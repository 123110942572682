import CollabFoodie from "./CollabFoodie";

class Foodie {
    constructor(data) {
        this.data = data;

        // Assume that pivot is CollabFoodie for now, but can be other things too later
        // maybe add another constructor param to identify pivot type
        if ('pivot' in data) {
            this.pivot = new CollabFoodie(data.pivot)
        }
    }

    getIgLink() {
        if (this.hasInstagram()) {
            return 'https://instagram.com/' + this.data.ig_username
        }
    }

    getIgString() {
        if (this.hasInstagram()) {
            return '@' + this.data.ig_username;
        }
    }

    hasInstagram () {
        return this.data.ig_username !== null
    }
}

export default Foodie;