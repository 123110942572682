class Restaurant {
    constructor(data, isSlim = false) {
        this.data = data;
        this.isSlim = isSlim
    }

    getIgLink() {
        if (this.hasInstagram()) {
            return 'https://instagram.com/' + this.data.ig_username
        }
    }

    getIgString() {
        if (this.hasInstagram()) {
            return '@' + this.data.ig_username;
        }
    }

    hasInstagram () {
        return this.data.ig_username !== null
    }

    isVerified() {
        return this.data.verified;
    }
}

export default Restaurant;