class CollabFoodie {
    constructor(data) {
        this.data = data;
    }

    restaurantIsRated() {
        return this.data.restaurant_rated;
    }

    foodieIsRated() {
        return this.data.foodie_rated;
    }

    getStatusString() {
        return CollabFoodie.statusMap(this.data.status)
    }

    isPending() {
        return this.data.status === 'pending';
    }

    isPendingConfirmation() {
        return this.data.status === 'pending_confirmation';
    }

    isIgnored() {
        return this.data.status === 'ignored';
    }

    isAccepted() {
        return this.data.status === 'accepted';
    }

    isCanceled() {
        return this.data.status === 'canceled';
    }

    isExpired() {
        return this.data.status === 'expired';
    }

    requiresConfirmation() {
        return this.isPendingConfirmation();
    }

    static statusMap(status) {
        const map = {
            'pending': 'Pendiente',
            'pending_confirmation': 'Por confirmar',
            'ignored': 'Ignoro',
            'accepted': 'Aceptado',
            'completed': 'Completado',
            'canceled': 'Cancelado',
            'expired': 'Expirado',
        };

        return map[status] ?? status;
    }
}

export default CollabFoodie;